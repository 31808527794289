import React, { useEffect, useState } from "react";
import { Root } from "./styles";
import { Box, Container } from "@mui/system";
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import { useGlobalContext } from "../../../context/Global";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import LayoutOne from "../Components/Layout";
/* eslint-disable import/no-webpack-loader-syntax */
import NextButton from "-!svg-react-loader!../../../assets/icons/next-arrow.svg";
import WarningIcon from "-!svg-react-loader!../../../assets/icons/warning-icon.svg";
import { countries } from "../../../const/checkout";
import handleLocalStorage from "../../../utils/useLocalStorage";
import { useTrackContext } from "../../../context/Tracking";
import { CartItem } from "../../../utils/cartItems";
import { useIntl } from "react-intl";
import { addItemToSessionStorage } from "../../../utils/sesstionStorage";
import { languages } from "../../../const/languages";
import { IFullMenu } from "../../../const";
import {
    parsePhoneNumber,
    // isValidPhoneNumber,
    CountryCode
} from "libphonenumber-js";

const CheckoutPage = () => {
    const {
        fullMenu,
        cartData,
        collection,
        checkoutStep,
        setCheckoutStep,
        checkoutResponse,
        setCheckoutResponse,
        formData,
        setFormData
    } = useGlobalContext();

    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    // get sessionData
    const { getItem, removeItem } = handleLocalStorage();
    const sessionData = getItem("sessionData");

    const [successMessage, setSuccessMessage] = useState(
        intl.formatMessage({
            id: "checkout.orderReceived"
        })
    );

    const [errorMessage, setErrorMessage] = useState(
        intl.formatMessage({
            id: "checkout.orderUnsuccessfulDesc"
        })
    );

    const [loading, setLoading] = useState(false);
    const checkoutData = fullMenu?.checkout_data;
    const guestLocation = checkoutData?.guest_location;
    const guestName = checkoutData?.guest_name;
    const guestMemberId = checkoutData?.guest_member_id;
    const guestPhone = checkoutData?.guest_phone;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isLang = urlSearchParams.get("lang");
    const { available_languages, source_language } = fullMenu as IFullMenu;

    const currentLang =
        isLang && isLang in languages && available_languages?.includes(isLang)
            ? isLang
            : source_language;

    const [formErrors, setFormErrors] = useState({
        location: "",
        name: "",
        memberId: "",
        phoneNumber: ""
    });

    const { newEvent } = useTrackContext();

    // Clear Cart
    function clearfavorites() {
        const cartItem = new CartItem(`${fullMenu?.identifier}`);
        cartItem.clearCartItem();

        newEvent(
            "clear-bookmark",
            {
                template: "1"
            },
            fullMenu?.identifier || ""
        );
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const isLoc = urlSearchParams.get("loc");
        // Set default values
        setFormData((prevData: any) => ({
            ...prevData,
            location: isLoc
                ? isLoc
                : sessionData && sessionData?.location
                ? sessionData?.location
                : prevData?.location,

            name:
                sessionData && sessionData?.name
                    ? sessionData?.name
                    : prevData?.name,
            memberId:
                sessionData && sessionData?.member_id
                    ? sessionData?.member_id
                    : prevData?.memberId,
            countryCode:
                sessionData && sessionData?.phone?.country_code
                    ? sessionData?.phone?.country_code
                    : prevData?.countryCode,
            phoneNumber:
                sessionData && sessionData?.phone?.number
                    ? sessionData?.phone?.number
                    : prevData?.phoneNumber
        }));

        // Check if guest_location is inactive, then set checkout step to 2
        if (
            guestLocation?.status === "inactive" ||
            ((sessionData?.location || isLoc) && guestLocation?.skip_if_known)
        ) {
            setCheckoutStep(2);
            // Check if guest_name and member_id is inactive, then set checkout step to 3
            if (
                (guestName?.status === "inactive" ||
                    (sessionData?.name && guestName?.skip_if_known)) &&
                (guestMemberId?.status === "inactive" ||
                    (sessionData?.member_id && guestMemberId?.skip_if_known))
            ) {
                setCheckoutStep(3);
                // Check if guest_phone is inactive, then set checkout step to 4
                if (
                    guestPhone?.status === "inactive" ||
                    (sessionData?.phone?.number && guestPhone?.skip_if_known)
                ) {
                    setCheckoutStep(4);
                    // Check if skipped all the steps
                    setTimeout(() => {
                        // Clear favirotes
                        clearfavorites();

                        // Clear Session Data
                        removeItem("sessionData");
                    }, 1000);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Helper function to get country ISO code from country code
    const getCountryISOCode = (dialCode: number): CountryCode => {
        const country = countries.find((c) => c.code === dialCode);
        return (country?.isoCode || "US") as CountryCode;
    };

    // Main phone validation function
    const validatePhoneNumber = (
        phoneNumber: string,
        countryCode: CountryCode
    ): boolean => {
        try {
            const phoneNo = parsePhoneNumber(phoneNumber, countryCode);
            if (!phoneNo) {
                return false;
            }

            // if (!isValidPhoneNumber(phoneNumber, countryCode)) {
            //     return false;
            // }

            // Check if the parsed number is valid for the given country
            // if (!phoneNo.isValid()) {
            //     return false;
            // }

            // Check if the national number is exactly 10 digits
            const nationalNumber = phoneNo.nationalNumber;

            if (nationalNumber.length !== 10) {
                return false;
            }

            // Check if the parsed number matches the input (to catch extra digits)
            if (phoneNo.number !== phoneNo.format("E.164")) {
                return false;
            }

            return true;
        } catch (error) {
            return false;
        }
    };

    const data = fullMenu?.presenter_template;

    const locationLabel = guestLocation?.label;
    const nameLabel = guestName?.label;
    const phoneLabel = guestPhone?.label;
    const memberIdLabel = guestMemberId?.label;

    const getLocationOptionalText = () =>
        guestLocation?.status === "optional"
            ? `(${intl.formatMessage({ id: "checkout.optional" })})`
            : "";

    const locationFieldLabel = `${locationLabel} ${getLocationOptionalText()}`;

    const nameOptionalText = () =>
        guestName?.status === "optional"
            ? `(${intl.formatMessage({ id: "checkout.optional" })})`
            : "";

    const nameFieldLabel = `${nameLabel} ${nameOptionalText()}`;

    const getMemberIdOptionalText = () =>
        guestMemberId?.status === "optional"
            ? `(${intl.formatMessage({ id: "checkout.optional" })})`
            : "";

    const memberIdFieldLabel = `${memberIdLabel} ${getMemberIdOptionalText()}`;

    const getPhoneOptionalText = () =>
        guestPhone?.status === "optional"
            ? `(${intl.formatMessage({ id: "checkout.optional" })})`
            : "";

    const phoneFieldLabel = `${phoneLabel} ${getPhoneOptionalText()}`;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // Allow only numeric input for phoneNumber
        if (name === "phoneNumber" && !/^\d*$/.test(value)) {
            return; // Exit if non-numeric input is detected
        }

        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Validate fields based on the current step
    const validateFields = () => {
        const errors: any = {};

        if (
            checkoutStep === 1 &&
            (checkoutData?.guest_location?.status === "mandatory"
                ? !formData?.location
                : false)
        ) {
            errors.location = intl.formatMessage(
                {
                    id: "checkout.locationRequired"
                },
                {
                    locationLabel
                }
            );
        }

        if (checkoutStep === 2) {
            if (
                checkoutData?.guest_name?.status === "mandatory"
                    ? !formData?.name
                    : false
            ) {
                errors.name = intl.formatMessage(
                    {
                        id: "checkout.nameRequired"
                    },
                    {
                        nameLabel
                    }
                );
            }
            if (
                checkoutData?.guest_member_id?.status === "mandatory"
                    ? !formData?.memberId
                    : false
            ) {
                errors.memberId = intl.formatMessage(
                    {
                        id: "checkout.memberIdRequired"
                    },
                    {
                        memberIdLabel
                    }
                );
            }
        }

        if (checkoutStep === 3) {
            // Case 1: If guest_phone status is "optional" and phoneNumber exists, validate it
            if (checkoutData?.guest_phone?.status === "optional") {
                const countryISO = getCountryISOCode(formData.countryCode);
                if (
                    formData?.phoneNumber &&
                    !validatePhoneNumber(formData.phoneNumber, countryISO)
                ) {
                    errors.phoneNumber = intl.formatMessage(
                        {
                            id: "checkout.phoneNumberInvalid"
                        },
                        {
                            phoneLabel
                        }
                    );
                }
            }

            // Case 2: If guest_phone status is "mandatory", check if phoneNumber is blank or invalid
            if (checkoutData?.guest_phone?.status === "mandatory") {
                const countryISO = getCountryISOCode(formData.countryCode);
                if (!formData?.phoneNumber) {
                    errors.phoneNumber = intl.formatMessage(
                        {
                            id: "checkout.phoneNumberRequired"
                        },
                        {
                            phoneLabel
                        }
                    );
                } else if (
                    !validatePhoneNumber(formData.phoneNumber, countryISO)
                ) {
                    errors.phoneNumber = intl.formatMessage(
                        {
                            id: "checkout.phoneNumberInvalid"
                        },
                        {
                            phoneLabel
                        }
                    );
                }
            }
        }
        setFormErrors(errors);

        // Return true if no errors, false otherwise
        return Object.keys(errors).length === 0;
    };

    const handleNext = () => {
        // Run validation
        if (!validateFields()) {
            return; // Stop execution if validation fails
        }

        setCheckoutStep((prevStep) => {
            // If current step is "location", move to "nameMemberId", but skip if both guest_name and guest_member_id are inactive
            if (prevStep === 1) {
                if (
                    (checkoutData?.guest_name?.status === "inactive" ||
                        (sessionData?.name &&
                            checkoutData?.guest_name?.skip_if_known)) &&
                    (checkoutData?.guest_member_id?.status === "inactive" ||
                        (sessionData?.member_id &&
                            checkoutData?.guest_member_id?.skip_if_known))
                ) {
                    // Skip directly to phone step
                    if (
                        checkoutData?.guest_phone?.status === "inactive" ||
                        (sessionData?.phone?.number &&
                            checkoutData?.guest_phone?.skip_if_known)
                    ) {
                        // Skip to complete if phone is inactive
                        handleComplete();
                    } else {
                        return 3; // Move to phone step
                    }
                } else {
                    return 2; // Move to next step normally
                }
            }

            // If current step is "nameMemberId", move to "phone", but skip if guest_phone is inactive
            if (prevStep === 2) {
                if (
                    checkoutData?.guest_phone?.status === "inactive" ||
                    (sessionData?.phone?.number &&
                        checkoutData?.guest_phone?.skip_if_known)
                ) {
                    // Skip to complete if phone is inactive
                    handleComplete();
                } else {
                    return 3; // Move to phone step
                }
            }

            // If current step is "phone", move to "complete"
            if (prevStep === 3) {
                handleComplete();
            }

            return prevStep; // Default: return current step if none of the conditions match
        });
    };

    const handleComplete = async () => {
        // Run validation
        if (!validateFields()) {
            return; // Stop execution if validation fails
        }

        setLoading(true);
        setFormErrors({
            location: "",
            name: "",
            memberId: "",
            phoneNumber: ""
        });

        const body = {
            instance_id: fullMenu?.instance,
            session: sessionData?.short_code,
            location: formData?.location,
            name: formData?.name,
            memberID: formData?.memberId,
            phone: formData?.phoneNumber,
            language: currentLang,
            country_code: parseInt(formData?.countryCode),
            order: cartData.map((item: any) => {
                return {
                    item: item?.display_name,
                    quantity: item?.item_length,
                    notes: item?.item_message,
                    ...(item?.price > 0 && { price: item?.price }),
                    ...(formData?.location && { location: formData.location })
                };
            })
        };
        try {
            // Replace this with your actual API endpoint
            const response = await fetch(
                `${process.env.REACT_APP_TRACK_URL}/cart/place-order/${fullMenu?.identifier}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(body)
                }
            );

            const data = await response.json();

            if (response.ok && !data?.data?.code) {
                const orderMessage =
                    data?.data?.message.find(
                        (msg: any) => msg.language === currentLang
                    )?.message ||
                    intl.formatMessage({
                        id: "checkout.orderReceived"
                    });

                setSuccessMessage(orderMessage);
                setCheckoutStep(4);
                setCheckoutResponse(data);

                // Check if the session is already present and session exists in the API response
                const searchParams = new URLSearchParams(location.search);

                if (data?.data?.session) {
                    // Append the session to the URL
                    searchParams.append("session", data.data.session);

                    // store to session storage
                    addItemToSessionStorage("sessionMode", data.data.session);
                    // Navigate to the new URL with the session appended
                    navigate(
                        {
                            pathname: location.pathname,
                            search: searchParams.toString()
                        },
                        { replace: true }
                    );
                }

                clearfavorites();

                // Clear Session Data
                removeItem("sessionData");
            } else {
                // Check if there are validation errors in the response
                if (data.message && Array.isArray(data.message)) {
                    const errorMessages = data.message
                        .map((err: any) => {
                            if (typeof err === "object" && err.message) {
                                setErrorMessage(
                                    err.message ||
                                        intl.formatMessage({
                                            id: "checkout.orderReceived"
                                        })
                                );
                                return err.message;
                            }
                            return String(err);
                        })
                        .join(". ");
                    throw new Error(errorMessages);
                } else {
                    setErrorMessage(
                        data.message ||
                            data.data.message ||
                            intl.formatMessage({
                                id: "checkout.orderReceived"
                            })
                    );
                    throw new Error(
                        data.message ||
                            data.data.message ||
                            "Failed to submit order"
                    );
                }
            }
        } catch (err: any) {
            setCheckoutStep(4);
            // Clear Session Data
            removeItem("sessionData");
            if (typeof err === "object" && err.message) {
                console.log(err, "err");
            } else {
                console.log("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    };

    // Reference to the custom container for the dropdown
    const customContainerRef = React.useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    const headerStyle1 = {
        fontFamily: checkoutData?.fonts?.header_text_1?.family,
        fontSize: checkoutData?.fonts?.header_text_1?.size,
        fontWeight: checkoutData?.fonts?.header_text_1?.weight,
        fontStyle: checkoutData?.fonts?.header_text_1?.style,
        lineHeight: checkoutData?.fonts?.header_text_1?.line_height,
        color: checkoutData?.fonts?.header_text_1?.color
    };

    const headerStyle2 = {
        fontFamily: checkoutData?.fonts?.header_text_2?.family,
        fontSize: checkoutData?.fonts?.header_text_2?.size,
        fontWeight: checkoutData?.fonts?.header_text_2?.weight,
        fontStyle: checkoutData?.fonts?.header_text_2?.style,
        lineHeight: checkoutData?.fonts?.header_text_2?.line_height,
        color: checkoutData?.fonts?.header_text_2?.color
    };

    const nameHeaderText = guestName?.header_text
        ?.replace(
            /\[name label\]/gi,
            guestName?.status !== "inactive" && nameLabel ? nameLabel : ""
        ) // Case-insensitive replacement for [name label]
        .replace(
            /\[member id label\]/gi,
            guestMemberId?.status !== "inactive" && memberIdLabel
                ? memberIdLabel
                : ""
        ) // Case-insensitive replacement for [member id label]
        .replace(
            /\[and\]/gi,
            guestName?.status !== "inactive" &&
                guestMemberId?.status !== "inactive" &&
                nameLabel &&
                memberIdLabel
                ? "and"
                : ""
        ) // Case-insensitive replacement for [and]
        .replace(/\s+/g, " ") // Clean up extra spaces
        .trim(); // Trim the final string for a clean result

    const renderStep = () => {
        switch (checkoutStep) {
            case 1:
                return (
                    <Box className="steps-wrapper">
                        <h2 style={headerStyle1}>
                            {guestLocation?.header_text}
                        </h2>
                        <TextField
                            type="text"
                            name="location"
                            autoFocus
                            value={formData.location}
                            onChange={handleInputChange}
                            placeholder={`${intl.formatMessage(
                                {
                                    id: "checkout.enterLocation"
                                },
                                { locationLabel }
                            )}`}
                            label={locationFieldLabel}
                            className="form-input"
                        />
                        {formErrors?.location ? (
                            <p className="error">
                                <WarningIcon />
                                {formErrors?.location}
                            </p>
                        ) : (
                            ""
                        )}
                        {guestLocation?.help_text ? (
                            <p style={headerStyle2} className="helper-text">
                                {guestLocation?.help_text}
                            </p>
                        ) : (
                            ""
                        )}
                    </Box>
                );
            case 2:
                return (
                    <Box className="steps-wrapper">
                        <h2 style={headerStyle1}>{nameHeaderText}</h2>
                        {guestName?.status !== "inactive" && (
                            <Box className="form-group">
                                <TextField
                                    type="text"
                                    name="name"
                                    autoFocus
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder={`${intl.formatMessage(
                                        {
                                            id: "checkout.enterName"
                                        },
                                        {
                                            nameLabel
                                        }
                                    )}`}
                                    className="form-input"
                                    label={nameFieldLabel}
                                    autoComplete="name"
                                />
                                {formErrors?.name ? (
                                    <p className="error">
                                        <WarningIcon />
                                        {formErrors?.name}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </Box>
                        )}
                        {guestMemberId?.status !== "inactive" && (
                            <Box className="form-group">
                                <TextField
                                    className="form-input"
                                    type="text"
                                    name="memberId"
                                    autoFocus={guestName?.status === "inactive"}
                                    value={formData.memberId}
                                    onChange={handleInputChange}
                                    placeholder={`${intl.formatMessage(
                                        {
                                            id: "checkout.enterMemberId"
                                        },
                                        { memberIdLabel }
                                    )}`}
                                    label={memberIdFieldLabel}
                                />
                                {formErrors?.memberId ? (
                                    <p className="error">
                                        <WarningIcon />
                                        {formErrors?.memberId}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </Box>
                        )}
                    </Box>
                );
            case 3:
                return (
                    <Box className="steps-wrapper step-3">
                        <h2 style={headerStyle1}>{guestPhone?.header_text1}</h2>
                        <p style={headerStyle2} className="helper-text-top">
                            {guestPhone?.header_text2}
                        </p>
                        <FormControl fullWidth className="form-input">
                            <InputLabel id="countryCode">
                                {intl.formatMessage({
                                    id: "checkout.countryCode"
                                })}
                            </InputLabel>
                            <Select
                                labelId="countryCode"
                                id="country-code-select"
                                value={formData.countryCode}
                                onChange={(e) =>
                                    setFormData((prevData: any) => ({
                                        ...prevData,
                                        countryCode: e.target.value
                                    }))
                                }
                                label={intl.formatMessage({
                                    id: "checkout.countryCode"
                                })}
                                MenuProps={{
                                    PaperProps: {
                                        className: "custom-select-wrapper"
                                    },
                                    container: customContainerRef.current // Set custom container for dropdown
                                }}
                            >
                                {countries.map((country, index) => (
                                    <MenuItem
                                        key={`${country.code}--${index}`}
                                        value={country.code}
                                    >
                                        {`${country.name} (+${country.code})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box className="phone-wrapper">
                            {(isFocused || formData.phoneNumber) && (
                                <Box className="prefix-code">{`+${formData.countryCode}`}</Box>
                            )}
                            <TextField
                                className="form-input"
                                type="tel"
                                name="phoneNumber"
                                autoFocus
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                placeholder={`${
                                    formData.countryCode === 1
                                        ? "XXX XXX XXXX"
                                        : ""
                                }`}
                                label={phoneFieldLabel}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                inputProps={{
                                    inputMode: "numeric", // Numeric keyboard for mobile devices
                                    pattern: "[0-9]*", // Allow only numbers
                                    maxLength: 10 // Optional: max length restriction
                                }}
                            />
                        </Box>
                        {formErrors?.phoneNumber ? (
                            <p className="error">
                                <WarningIcon />
                                {formErrors?.phoneNumber}
                            </p>
                        ) : (
                            ""
                        )}
                    </Box>
                );
            case 4:
                if (checkoutResponse) {
                    return (
                        <Box className="steps-wrapper">
                            <h2 style={headerStyle1}>{successMessage}</h2>
                            {/* <p className="helper-text-top">
                                {intl.formatMessage({
                                    id: "checkout.orderReceivedDesc"
                                })}
                            </p> */}
                            <Box className="ordered-page">
                                {formData?.location || sessionData?.location ? (
                                    <Box className="tag">
                                        {formData?.location ||
                                            sessionData?.location}
                                    </Box>
                                ) : (
                                    ""
                                )}
                                {formData?.name || sessionData?.name ? (
                                    <Box className="username">
                                        {formData?.name || sessionData?.name}
                                    </Box>
                                ) : (
                                    ""
                                )}
                                {formData?.memberId || sessionData?.memberId ? (
                                    <Box className="user-id">
                                        ID#:{" "}
                                        {formData?.memberId ||
                                            sessionData?.memberId}
                                    </Box>
                                ) : (
                                    ""
                                )}
                                <ul className="items-list">
                                    {cartData?.map((item: any, index) => (
                                        <li
                                            key={index}
                                            dangerouslySetInnerHTML={{
                                                __html: `${
                                                    item?.item_length
                                                } x ${item?.display_name} ${
                                                    item?.item_message
                                                        ? `(${item?.item_message})`
                                                        : ""
                                                }`
                                            }}
                                        ></li>
                                    ))}
                                </ul>
                            </Box>
                        </Box>
                    );
                } else {
                    return (
                        <Box className="steps-wrapper">
                            <h2 style={headerStyle1}>
                                {intl.formatMessage({
                                    id: "checkout.orderUnsuccessful"
                                })}
                            </h2>
                            <p style={headerStyle2}>{errorMessage}</p>
                        </Box>
                    );
                }
            default:
                return null;
        }
    };

    if (!cartData?.length) {
        return <Navigate to={`/${fullMenu?.identifier}/cart`} />;
    }

    const progressCount =
        checkoutStep < 4 ? Math.round((checkoutStep / 3) * 100) : 100;

    return (
        <LayoutOne
            title={fullMenu?.display_name}
            subtitle={collection?.collection_id?.display_name}
            showSearch={false}
            showNavbar={false}
            showNotationToggle={false}
            templateConfigs={{}}
            template={fullMenu?.presenter_template || {}}
        >
            <Container
                maxWidth="md"
                style={{ padding: "0 0 0 0", position: "relative" }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Box className="section-wrapper">
                            <Root
                                className="checkout-wrapper"
                                template={data}
                                ref={customContainerRef}
                            >
                                {renderStep()}
                                {checkoutStep < 4 ? (
                                    <Box className="checkout-footer-wrapper">
                                        <Box className="progress-bar-wrapper">
                                            <Box className="progress-bar">
                                                <Box
                                                    style={{
                                                        width: `${progressCount}%`
                                                    }}
                                                    className="inner-bar"
                                                >
                                                    &nbsp;
                                                </Box>
                                            </Box>
                                            <Box className="progress-count">
                                                {progressCount}%
                                            </Box>
                                        </Box>
                                        {checkoutStep < 3 ? (
                                            <Box className="btn-wrapper">
                                                <Button
                                                    className="primary-btn"
                                                    onClick={handleNext}
                                                    endIcon={
                                                        !loading ? (
                                                            <NextButton />
                                                        ) : (
                                                            ""
                                                        )
                                                    }
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    ) : (
                                                        intl.formatMessage({
                                                            id: "checkout.next"
                                                        })
                                                    )}
                                                </Button>
                                            </Box>
                                        ) : (
                                            <Box className="btn-wrapper">
                                                <Button
                                                    className="primary-btn"
                                                    onClick={handleComplete}
                                                    endIcon={
                                                        !loading ? (
                                                            <NextButton />
                                                        ) : (
                                                            ""
                                                        )
                                                    }
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    ) : (
                                                        intl.formatMessage({
                                                            id: "checkout.complete"
                                                        })
                                                    )}
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    ""
                                )}
                            </Root>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </LayoutOne>
    );
};

export default CheckoutPage;
