import { styled } from "@mui/material/styles";
interface RootProps {
    props: Record<string, any>;
}
const classes = {
    svg: `svg`,
    selectedLanguage: `selectedLanguage`,
    button: `button`,
    buttonContainer: `buttonContainer`,
    languageDrawer: `languageDrawer`,
    selectLanguageTitle: `selectLanguageTitle`,
    optionsContainer: `optionsContainer`,
    option: `option`,
    handle: `handle`,
    radio: `radio`
};

const Root = styled("div")<RootProps>(({ props }) => ({
    "&.rootWrapper": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 20,
        paddingLeft: 20
    },
    "& .checkoutBackButton": {
        position: "fixed",
        top: 20,
        zIndex: 9
    },
    "& .backToMenu": {
        display: "flex",
        alignItems: "center",
        fontSize: 18,
        cursor: "pointer",
        "& svg": {
            marginRight: 14
        }
    },
    [`& .${classes.svg}`]: {
        "& path": {
            fill: props.text_color || "black"
        }
    },
    [`& .${classes.selectedLanguage}`]: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        padding: "6px 8px",
        fontSize: 11
    },
    [`& .${classes.button}`]: {
        all: "unset",
        display: "flex",
        flexDirection: "row-reverse",
        cursor: "pointer",
        border: `1px solid ${props.border_color || "black"}`,
        borderRadius: 4,
        background: props.background_color || "transparent",
        color: props.text_color || "black",
        margin: 14
    },
    [`& .${classes.buttonContainer}`]: {
        width: "100%",
        display: "flex",
        flexDirection: "row-reverse"
    },
    [`&.${classes.languageDrawer}`]: {
        background: "white",
        borderRadius: "20px 20px 0 0",
        padding: "0 24px",
        color: props.drawer_text_color || "black",
        fontFamily: props.family || "Poppins",
        position: "relative"
    },
    [`& .${classes.selectLanguageTitle}`]: {
        fontSize: 18,
        lineHeight: "27px",
        color: props.drawer_text_color || "black",
        fontFamily: props.family || "Poppins",
        fontWeight: 500,
        margin: "40px 0 0 0",
        paddingBottom: 6,
        borderBottom: "1px solid rgba(192, 192, 192, 0.5)"
    },
    [`& .${classes.optionsContainer}`]: { padding: "12px 0 16px 0" },
    [`& .${classes.option}`]: {
        display: "flex",
        flexDirection: "row",
        gap: 10,
        fontSize: 14,
        padding: "5px 0 5px 0",
        alignItems: "center",
        cursor: "pointer"
    },
    [`& .${classes.handle}`]: {
        position: "absolute",
        top: 10,
        left: "calc(50% - 25px)",
        width: 50,
        height: 6,
        borderRadius: 6,
        background: "#EAEAEA"
    },
    [`& .${classes.radio}`]: {
        color: `${props.radio_color || "#CC5803"} !important`
    }
}));

export { Root, classes };
